import axios from "axios";
import store from "./index";
import router from "../router/index";
import { MUTATION } from "./types";

import Vue from "../main";

const Axios = axios.create({
  baseURL: process.env.VUE_APP_AGRI_API_PATH
});

Axios.interceptors.request.use(Request => {
  let token = store.state.token;
  token && (Request.headers["Authorization"] = "Bearer " + token);
  return Request;
});

Axios.interceptors.response.use(
  res => {
    let token = res.headers["authorization"];
    if (token !== undefined && typeof token === "string") {
      token = token.substr(7);
      store.commit(MUTATION.setToken, token);
    }

    return res;
  },
  err => {
    if (err.response) {
      switch (err.response.status) {
        case 401:
          Vue.$bvToast.toast("redirect to login page", {
            title: "API Error 401 Unauthrorized",
            variant: "danger",
            autoHideDelay: 3000
          });
          store.commit(MUTATION.setToken, null);
          router.push({ name: "Login" });
          break;
        case 403:
          Vue.$bvToast.toast(
            "wrond data (relation) , " +
              JSON.stringify(err.response.data["message"]),
            {
              title: "API Error 403 Forbidden",
              variant: "danger",
              autoHideDelay: 10000
            }
          );
          break;
        case 404:
          Vue.$bvToast.toast("沒有這隻API ", {
            title: "API Error 404 Not Found ",
            variant: "danger",
            autoHideDelay: 10000
          });
          break;
        case 422:
          Vue.$bvToast.toast(
            "wrong data (format) , " +
              JSON.stringify(err.response.data["message"]),
            {
              title: "API Error 422 Unprocessable Entity",
              variant: "danger",
              autoHideDelay: 10000
            }
          );
          break;
        case 500:
          Vue.$bvToast.toast(
            "請聯絡阿龜找出錯誤 , " +
              JSON.stringify(err.response.data["message"]),
            {
              title: "API Error 500 Internal Server Error",
              variant: "danger",
              autoHideDelay: 10000
            }
          );
          break;
      }
    }

    return Promise.reject(err);
  }
);

export { Axios };
