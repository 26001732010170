import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import { MUTATION, ACTION } from "./types";
import { Axios } from "./axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    me: {
      id: null,
      email: null,
      is_admin: null,
      is_headJudge: null,
      code: null,
      name: null,
      phone: null,
      address: null,
      created_at: null,
      updated_at: null
    }
  },
  mutations: {
    [MUTATION.setToken](state, _token) {
      state.token = _token;
    },
    [MUTATION.setMyData](state, payload) {
      Object.assign(state.me, payload);
    }
  },
  actions: {
    async [ACTION.login](context, { _email, _password }) {
      let res = await Axios.post("api/auth/login", {
        email: _email,
        password: _password
      });
      if (res.status === 200) {
        context.commit(MUTATION.setToken, res.data["access_token"]);
        context.dispatch(ACTION.getMyData);
      }
      return res;
    },
    async [ACTION.logout](context) {
      let res = await Axios.post("/api/auth/logout");
      if (res.status === 200) {
        context.commit(MUTATION.setToken, null);
        let MeObject = {};
        Object.keys(context.state.me).forEach(key => (MeObject[key] = null));
        context.commit(MUTATION.setMyData, MeObject);
      }
      return res;
    },
    async [ACTION.getMyData](context) {
      let res = await Axios.get("/api/me");
      if (res.status === 200) {
        context.commit(MUTATION.setMyData, res.data);
      }
      return res;
    }
  },
  modules: {},
  plugins: [createPersistedState()]
});
