<template>
  <b-navbar toggleable="lg" type="dark" variant="info">
    <b-navbar-brand :to="{ name: 'HistoryEvent' }">
      <b-img height="35px" src="../assets/logo.svg" />
    </b-navbar-brand>

    <router-link
      class="d-flex flex-column"
      :to="{ name: 'HistoryEvent' }"
      style="color:black;"
    >
      <h1 class="m-0" style="font-size:28px;">咖啡評測系統</h1>
      <h2 class="m-0" style="font-size:18px;">Coffee Judge System</h2>
    </router-link>

    <b-navbar-toggle
      v-if="$store.state.token !== null"
      target="nav-collapse"
    ></b-navbar-toggle>

    <b-collapse v-if="$store.state.token !== null" id="nav-collapse" is-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-text>
          <p class="userNameText m-0">
            {{
              $store.state.me.name +
                ($store.state.me.code !== null
                  ? ` [${$store.state.me.code}]`
                  : "")
            }}
          </p></b-nav-text
        >
        <b-nav-item
          v-if="$store.state.me.is_admin"
          :to="{ name: 'Management' }"
          class="my-auto"
          v-b-tooltip.hover
          title="點擊前往系統管理界面"
        >
          管理員
        </b-nav-item>
        <b-nav-text
          class="my-auto mx-2"
          v-else-if="$store.state.me.is_headJudge"
        >
          評審長
        </b-nav-text>
        <b-nav-text class="my-auto mx-2" v-else>
          評審員
        </b-nav-text>
        <b-btn variant="primary" @click="logout()">登出</b-btn>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { ACTION } from "../store/types";
export default {
  name: "navbar",
  props: {},
  methods: {
    logout() {
      this.$store
        .dispatch(ACTION.logout)
        .then(() => this.$router.push({ name: "Login" }));
    }
  }
};
</script>
<style lang="scss">
.userNameText {
  font-size: 18px;
  font-weight: 400;
  color: black;
}
</style>
