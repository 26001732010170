export const MUTATION = {
  setToken: "setToken",
  setMyData: "setMyData"
};

export const ACTION = {
  login: "login",
  logout: "logout",
  getMyData: "getMyData"
};
