import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import { MUTATION } from "../store/types";
import { API } from "../store/api";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "Login" }
  },
  {
    path: "/Login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Login.vue")
  },
  {
    path: "/Management",
    name: "Management",
    beforeEnter: (to, from, next) => {
      if (store.state.me.is_admin === false) {
        next({ name: "HistoryEvent" });
      } else {
        next();
      }
    },
    component: () =>
      import(/* webpackChunkName: "Management" */ "../views/Management.vue")
  },
  {
    path: "/HistoryEvent",
    name: "HistoryEvent",
    component: () =>
      import(
        /* webpackChunkName: "HistoryEvent" */ "../views/HistoryEvent.vue"
      ),
    meta: {
      breadcrumb: [
        {
          text: "歷史評鑑列表",
          to: { name: "HistoryEvent" }
        }
      ]
    }
  },
  {
    path: "/Event/:eventID/CoffeeBeanManagement",
    name: "CoffeeBeanManagement",
    props: route => {
      return { eventID: Number.parseInt(route.params.eventID, 10) };
    },
    component: () =>
      import(
        /* webpackChunkName: "CoffeeBeanManagement" */ "../views/CoffeeBeanManagement.vue"
      ),
    meta: {
      breadcrumb: [
        {
          text: "歷史評鑑列表",
          to: { name: "HistoryEvent" }
        }
      ]
    }
  },
  {
    path: "/Event/:eventID/EventDashboard",
    name: "EventDashboard",
    props: route => {
      return { eventID: Number.parseInt(route.params.eventID, 10) };
    },
    component: () =>
      import(
        /* webpackChunkName: "EventDashboard" */ "../views/EventDashboard.vue"
      ),
    meta: {
      breadcrumb: [
        {
          text: "歷史評鑑列表",
          to: { name: "HistoryEvent" }
        },
        {
          text: "評鑑儀錶板"
        }
      ]
    }
  },
  {
    path: "/Event/:eventID/Round/:roundID/EventStatus",
    name: "EventStatus",
    props: route => {
      return {
        eventID: Number.parseInt(route.params.eventID, 10),
        roundID: Number.parseInt(route.params.roundID, 10)
      };
    },
    component: () =>
      import(/* webpackChunkName: "EventStatus" */ "../views/EventStatus.vue"),
    meta: {
      breadcrumb: [
        {
          text: "歷史評鑑列表",
          to: { name: "HistoryEvent" }
        }
      ]
    }
  },

  {
    path: "/Event/:eventID/Round/:roundID/ScoreReview",
    name: "ScoreReview",
    props: route => {
      return {
        eventID: Number.parseInt(route.params.eventID, 10),
        roundID: Number.parseInt(route.params.roundID, 10)
      };
    },
    component: () =>
      import(/* webpackChunkName: "ScoreReview" */ "../views/ScoreReview.vue"),
    meta: {
      breadcrumb: [
        {
          text: "歷史評鑑列表",
          to: { name: "HistoryEvent" }
        }
      ]
    }
  },
  {
    path: "/Event/:eventID/Round/:roundID/Scoring/:scoreID",
    name: "Scoring",
    props: route => {
      return {
        eventID: Number.parseInt(route.params.eventID, 10),
        scoreID: Number.parseInt(route.params.scoreID, 10),
        roundID: Number.parseInt(route.params.roundID, 10)
      };
    },
    component: () =>
      import(/* webpackChunkName: "Scoring" */ "../views/Scoring.vue"),
    meta: {
      breadcrumb: [
        {
          text: "歷史評鑑列表",
          to: { name: "HistoryEvent" }
        }
      ]
    }
  },
  {
    // 404 NotFound page
    path: "*",
    name: "404Page"
    // component: () => import(/* webpackChunkName: "ScoringPage" */ "../views/ScoringPage.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash && to.hash !== "#top") {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            selector: to.hash,
            behavior: "smooth"
          });
        }, 1000);
      });
    }
  }
});

router.beforeEach(async (to, from, next) => {
  if ("eventID" in to.params && store.state.me.is_headJudge === false) {
    let eventID = Number.parseInt(to.params.eventID, 10);
    let raters = await API.GetRaters(eventID);
    let rater = raters.find(r => r.user_id === store.state.me.id);

    if (rater) {
      let is_headJudge = rater.type === "head";
      let code = rater.code;
      store.commit(MUTATION.setMyData, { is_headJudge, code });
    }
  } else if (!("eventID" in to.params)) {
    store.commit(MUTATION.setMyData, { is_headJudge: false, code: null });
  }
  next();
});

export default router;
