import { Axios } from "./axios.js";

export const API = {
  /**
   * @typedef {Object} User
   * @property {Number} id
   * @property {String} email
   * @property {String} name
   * @property {String} phone
   * @property {String} address
   * @property {String} created_at
   * @property {String} updated_at
   * @property {Boolean} is_admin
   */
  /**
   * GetUsers
   *
   * @returns {Promise<{users: Array.<User>,count:Number}>}
   */
  async GetUsers() {
    let res = await Axios.get("/api/admin/users");
    return { users: res.data["users"], count: res.data["count"] };
  },
  /**
   * Create User
   * @param {{ email:String, password:String, name:String, phone:String, address:String, is_admin:Boolean }} param0
   */
  async NewUser({ email, password, name, phone, address, is_admin }) {
    await Axios.post("/api/admin/auth/register", {
      email,
      password,
      name,
      phone,
      address,
      is_admin
    });
  },
  /**
   *
   * @param {Number} userID
   * @param {{ is_admin:Boolean, name:String, phone:String, address:String, passwd:String }} param1
   */
  async PUTUser(userID, { is_admin, name, phone, address, passwd }) {
    let res = await Axios.put(`/api/admin/users/${userID}`, {
      is_admin,
      name,
      phone,
      address,
      passwd
    });
    return res;
  },
  /**
   * @typedef {Object} Event
   * @property {Number} id
   * @property {String} name
   * @property {String} start_time
   * @property {String} end_time
   * @property {String} type
   * @property {String} status
   * @property {String} adviser
   * @property {String} organizer
   * @property {String} coorganizer
   * @property {String} implementer
   * @property {String} created_at
   * @property {Array.<Rater>} raters
   * @property {Number} rater_count
   * @property {Number} target_count
   */

  /**
   * Get Events
   * @param {Number} limit
   * @param {Number} skip
   * @returns {Promise<{events:Array.<Event>,count:Number}>}
   */
  async GetEvents(limit = 5, skip = 0) {
    let res = await Axios.get("/api/events?order_by=-updated_at", {
      params: { limit, skip }
    });
    return { events: res.data["events"], count: res.data["count"] };
  },
  /**
   * @param {Number} id
   * @returns {Promise<{events:Array.<Event>,count:Number}>}
   */
  async GetEvent(id = null) {
    let res = await Axios.get("/api/events?order_by=-updated_at", {
      params: { ids: id }
    });
    return { events: res.data["events"], count: res.data["count"] };
  },
  /**
   * Create Event
   * @param {{ name:String, start_time:String, end_time:String, type:String, adviser:String, organizer:String, coorganizer:String, implementer:String }} param0
   */
  async NewEvent({
    name,
    start_time,
    end_time,
    type,
    adviser,
    organizer,
    coorganizer,
    implementer
  }) {
    let res = await Axios.post("/api/admin/events", {
      name,
      start_time,
      end_time,
      type,
      adviser,
      organizer,
      coorganizer,
      implementer
    });
    return res;
  },
  async DeleteEvent(eventID) {
    let res = await Axios.delete(`/api/admin/events/${eventID}`);
    return res;
  },
  async PUTEvent({
    id,
    name,
    start_time,
    end_time,
    status,
    type,
    adviser,
    organizer,
    coorganizer,
    implementer
  }) {
    let res = await Axios.put(`/api/admin/events/${id}`, {
      name,
      start_time,
      end_time,
      status,
      type,
      adviser,
      organizer,
      coorganizer,
      implementer
    });
    return res;
  },
  async FinishEvent(eventID) {
    let res = await Axios.put(`/api/events/${eventID}/done`);
    return res;
  },
  /**
   *
   * @param {Number} eventID
   * @param {Array.<String>} BeanCodeList
   */
  async AddTargetsToEvent(eventID, BeanCodeList) {
    let res = await Axios.post(`/api/admin/events/${eventID}/targets`, {
      codes: BeanCodeList
    });
    return res;
  },
  /**
   * @typedef {Object} Target
   * @property {Number} id`
   * @property {Number=} event_id`
   * @property {String} code
   */

  /**
   *
   * @param {Number} eventID
   * @returns {Promise<Array.<Target>>}
   */
  async GetTargets(eventID) {
    let res = await Axios.get(`/api/events/${eventID}/targets`);
    return res.data["targets"];
  },
  /**
   * @typedef {Object} AdminTarget
   * @property {Number} id`
   * @property {Number} event_id`
   * @property {String} code
   * @property {String} name
   * @property {String} location
   * @property {String} process
   * @property {String} created_at
   * @property {String} updated_at
   */

  /**
   * @param {Number} eventID
   * @returns {Promise<{targets:Array.<AdminTarget>,count:Number}>}
   */
  async GetAdminTargets(eventID) {
    let res = await Axios.get(`/api/admin/targets`, {
      params: { event_ids: eventID }
    });
    return { targets: res.data["targets"], count: res.data["count"] };
  },
  /**
   *
   * @param {Number} targetID
   * @param {String} name
   * @param {String} location
   * @param {String} process
   */
  async PUTAdminTargets(targetID, name, location, process) {
    let res = await Axios.put(`api/admin/targets/${targetID}`, {
      name,
      location,
      process
    });
    return res;
  },
  /**
   * @typedef {Object} Rater
   * @property {Number} id
   * @property {Number} user_id
   * @property {String} type
   * @property {String} code
   * @property {String=} user_name
   *
   */

  /**
   *
   * @param {Number} eventID
   * @param {Array.<{Rater}>} raters
   */
  async AddRatersToEvent(eventID, raters) {
    let res = await Axios.post(`/api/admin/events/${eventID}/raters`, {
      raters
    });
    return res;
  },

  /**
   *
   * @param {Number} eventID
   * @returns {Promise<Array.<Rater>>}
   */
  async GetRaters(eventID) {
    let res = await Axios.get(`/api/events/${eventID}/raters`);
    return res.data["raters"];
  },

  /**
   * @typedef {Object} Round
   * @property {String} created_at
   * @property {String} updated_at 
   * @property {Number} event_id
   * @property {Number} id
   * @property {String} name
   * @property {String} status 
   * @property {Array.<Number>} rater_ids
   * @property {Array.<Number>} target_ids
   * @property {Array.<Rater>} raters
   * @property {Array.<Target>} targets
   
   */
  /**
   *
   * @param {Number} eventID
   * @returns {Promise<{rounds:Array.<Round>,event:Event}>}
   */
  async GetRounds(eventID) {
    let res = await Axios.get(`/api/events/${eventID}/rounds`);
    return { rounds: res.data["rounds"], event: res.data["event"] };
  },
  /**
   * @param {Number} roundID
   * @param {Number} eventID
   * @returns {Promise<{round:Round,event:Event}>}
   */
  async GetRound(eventID, roundID) {
    let res = await Axios.get(`/api/events/${eventID}/rounds`, {
      params: { ids: roundID }
    });
    return { round: res.data["rounds"][0], event: res.data["event"] };
  },
  /**
   *
   * @param {Number} eventID
   * @param {String} name
   * @param {Array.<Number>} target_ids
   * @param {Array.<Number>} rater_ids
   */
  async AddRound(eventID, name, target_ids, rater_ids) {
    let res = await Axios.post(`/api/events/${eventID}/rounds`, {
      name,
      target_ids,
      rater_ids
    });
    return res;
  },
  /**
   *
   * @param {Number} roundID
   * @param {String} name
   * @param {String} status
   * @param {Array.<Number>} target_ids
   * @param {Array.<Number>} rater_ids
   */
  async PUTRound(roundID, name, status, target_ids, rater_ids) {
    let res = await Axios.put(`/api/rounds/${roundID}`, {
      status,
      target_ids,
      rater_ids,
      name
    });
    return res;
  },
  async DeleteRound(roundID) {
    let res = await Axios.delete(`/api/rounds/${roundID}`);
    return res;
  },

  /**
   * @typedef {Object} Score
   * @property {Number} acidity_intensity
   * @property {Array.<String>} acidity_neg
   * @property {Array.<String>} acidity_pos
   * @property {Number} acidity_score
   * @property {Array.<String>} aftertaste_neg
   * @property {Array.<String>} aftertaste_pos
   * @property {Number} aftertaste_score
   * @property {Number} aroma_break
   * @property {Number} aroma_dry
   * @property {Array.<String>} aroma_neg
   * @property {Array.<String>} aroma_pos
   * @property {Number} aroma_score
   * @property {Array.<String>} balance_neg
   * @property {Array.<String>} balance_pos
   * @property {Number} balance_score
   * @property {Number} body_intensity
   * @property {Array.<String>} body_neg
   * @property {Array.<String>} body_pos
   * @property {Number} body_score
   * @property {Array.<String>} cleancup_neg
   * @property {Array.<String>} cleancup_pos
   * @property {Number} cleancup_score
   * @property {Number} taint_cups
   * @property {Number} fault_cups
   * @property {Array.<String>} flavor_neg
   * @property {Array.<String>} flavor_pos
   * @property {Number} flavor_score
   * @property {Array.<String>} overall_neg
   * @property {Array.<String>} overall_pos
   * @property {Number} overall_score
   * @property {Array.<String>} sweetness_neg
   * @property {Array.<String>} sweetness_pos
   * @property {Number} sweetness_score
   * @property {Array.<String>} uniformity_neg
   * @property {Array.<String>} uniformity_pos
   * @property {Number} uniformity_score
   * @property {Number} final
   * @property {Number} total
   * @property {Number} defect
   * @property {String} target_code
   * @property {String} status
   * @property {Number} target_id
   * @property {Boolean} is_submittable
   * @property {Number} id
   */

  /**
   *
   * @param {Number} round_id
   * @param {Number} rater_id
   * @param {Number} target_id
   */
  async NewScore(round_id, rater_id, target_id) {
    let res = await Axios.post(`/api/cupping_scores`, {
      round_id,
      rater_id,
      target_id
    });
    return res;
  },
  /**
   *
   * @param {Score} score
   */
  async PUTScore(score) {
    let scoreID = score.id;

    Object.keys(score).forEach(key => {
      if (score[key] == null) {
        delete score[key];
      }
    });
    let res = await Axios.put(`/api/cupping_scores/${scoreID}/values`, {
      ...score
    });
    return res;
  },
  /**
   *
   * @param {Number} scoreID
   */
  async SubmitScore(scoreID) {
    let res = Axios.put(`/api/cupping_scores/${scoreID}/submit`);
    return res;
  },
  /**
   *
   * @param {Number} roundID
   * @returns {Promise<Array.<Score>> }
   */
  async GetMyRoundScores(roundID) {
    let res = Axios.get(`/api/rounds/${roundID}/rater_cupping_scores`);
    return (await res).data["scores"];
  },
  /**
   * @typedef {Object} ScoreStatus
   * @param {Number} rater_id
   * @param {String} rater_code
   * @param {Number} target_id
   * @param {String} target_code
   * @param {String} status
   * @param {Number} final
   */
  /**
   * @param {Number} roundID
   * @returns {Promise<Array.<ScoreStatus>>}
   */
  async GetAllRatersRoundFinalStatus(roundID) {
    let res = await Axios.get(`/api/rounds/${roundID}/raters_cupping_final`);
    return res.data["finals"];
  },
  /**
   * @typedef {Object}  summary
   * @property {Number} max
   * @property {Number} min
   * @property {Number} avg
   * @property {Number} trim_avg
   * @property {Number} count
   */
  /**
   *
   * @param {Number} roundID
   * @returns {Promise<Object.<String,summary>>}
   */
  async GetRatersCuppingSummary(roundID) {
    let res = await Axios.get(`/api/rounds/${roundID}/raters_cupping_summary`);
    return res.data["summaries"];
  },
  /**
   *
   * @param {Number} roundID
   * @param {Number} target_id
   * @returns {Promise<Array.<Score>>}
   */
  async GetRoundScores(roundID, target_id = undefined) {
    let res = await Axios.get(`/api/rounds/${roundID}/raters_cupping_score`, {
      params: { target_id }
    });
    return res.data["scores"];
  },
  /**
   *
   * @param {Number} eventID
   * @param {Array.<Number>} submitted
   * @param {Array.<Number>} resubmit
   * @param {Array.<Number>} ignore
   */
  async PUTCuppingScoreQueries(eventID, submitted, resubmit, ignore) {
    let res = Axios.put(`/api/events/${eventID}/cupping_score_queries`, {
      submitted,
      resubmit,
      ignore
    });
    return res;
  },
  async GetFile(eventID, FileCode) {
    let res = await Axios.get(`api/events/${eventID}/files/${FileCode}`);

    return {
      file_token: res.data["file_token"],
      file_name: res.data["file_name"]
    };
  }
};
